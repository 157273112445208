<template>
  <div role="group" :aria-label="label" class="input-group">
    <input
      v-for="n in fields"
      :key="`input-${n}`"
      ref="input"
      :value="values[n - 1]"
      :aria-label="$t('multi_input_label', { num: n })"
      type="number"
      required
      min="0"
      max="9"
      @paste.prevent="handlePaste"
      @keyup="e => handleInput(e, n)"
    >
  </div>
</template>

<script>

export default {
  name: 'MMultiInput',
  props: {
    fields: Number,
    label: String
  },
  data () {
    return {
      values: []
    }
  },
  methods: {
    handleInput (e, n) {
      if (e.key === 'ArrowLeft') {
        if (n > 1) this.$refs.input[n - 2].focus()
        return
      }

      if (e.key === 'ArrowRight') {
        if (n < this.fields) this.$refs.input[n].focus()
        return
      }

      if (e.key === 'Backspace') {
        // If there's already a value there, let it be deleted
        if (this.values[n - 1]) {
          this.values[n - 1] = ''
          this.$emit('input', this.values.join(''))
        } else if (n > 1) { // Else go back one space
          this.$refs.input[n - 2].focus()
        }
        return
      }

      if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.target.value = ''
      }

      if (!e.target.value) return

      // n starts at 1 in a v-for
      // Refs in a v-for are an array on the single ref name
      if (n < this.fields) {
        this.$refs.input[n].focus()
      }
      if (e.target.value.length > 1) e.target.value = e.target.value[e.target.value.length - 1]
      this.values[n - 1] = e.target.value
      this.$emit('input', this.values.join(''))
    },
    handlePaste (e) {
      // Split the pasted string into an array of characters
      const pastedValues = e.clipboardData.getData('text').split('')
      // Filter out non-numeric values
      const numericOnly = pastedValues.filter(value => value.match(/[0-9]/))
      // Shrink to max length of fields
      const maxLength = numericOnly.slice(0, this.fields)
      // Set values
      this.values = maxLength
      this.$emit('input', this.values.join(''))
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-group
  display flex
  justify-content space-evenly
  width 100%
</style>
